import { GlobalContextProvider } from 'contexts/globalContext';
import { AppProps } from 'next/app';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { urlToEventMapping } from 'utils/eventsConstant';
import useEvents from 'hook/useEvents';
import Header from '@components/molecules/Header';
import Wifi from '@components/atoms/Icons/wifi';
import Exclamation from '@components/atoms/Icons/Exclamation';
import RouteGuard from '@components/atoms/RouteGuard';
// import { Offline, Online } from '@components/_networkstatus';
import {
  DehydratedState,
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { getAuthData } from '@utils/storage';

import '@ninjacart/nc-components-ui/dist/index.css';
import '@ninjacart/nc-payments-ui/dist/index.scss';
import '@ninjacart/nc-ui-modules-v2/style';
import '../styles/transaction.css';
import '../styles/main.css';
import '../styles/output.css';
import '../styles/accordion.css';
import '../styles/PGDetails.css';
import { getEventsConfig } from '@utils/events';
import { initializeEventsSDK } from '@ninjacart/nc-events-sdk';
import useStorage from 'hook/useStorage';
import { STORAGE_KEYS } from '@utils/keys';
import { loadClarity } from '@utils/Utils';

interface CustomPageProps {
  dehydratedState: DehydratedState;
}

function MyApp({ Component, pageProps }: AppProps<CustomPageProps>) {
  const { triggerEvent } = useEvents();
  const router = useRouter();

  const { systemUserId, realmIdentifier, token, token_type, asgardUserId } =
    getAuthData();
  useEffect(() => {
    if (document && realmIdentifier) {
      document.cookie = `systemUserId=${systemUserId};secure`;
      document.cookie = `realmIdentifier = ${realmIdentifier};secure`;
      document.cookie = `token=${token};secure`;
      document.cookie = `token_type=${token_type};secure`;
      document.cookie = `asgardUserId=${asgardUserId};secure`;
    }
  }, [systemUserId, realmIdentifier, token, asgardUserId]);
  const [online, setOnline] = useState(true);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            enabled: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            structuralSharing: false,
            retry: false,
            cacheTime: 0,
          },
        },
      })
  );
  const { setItem, getItem } = useStorage();

  const sdkConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: `${process.env.NEXT_PUBLIC_SPLITIO_AUTHORIZATION_KEY}`,
      key: systemUserId?.toString() || '',
    },
  };

  useEffect(() => {
    setAppIdInStorage();
    loadClarity();
  }, []);


  // initialize events SDK on app mount and on changes of user login
  useEffect(() => {
    initializeEventsSDK(getEventsConfig());
    if (window.clarity) {
      window.clarity('set', 'userId', systemUserId);
    }
  }, [systemUserId]);

  useEffect(() => {
    const handleRouteChange = () => {
      const route =
        (window.location.pathname
          .split('/')
          .pop() as keyof typeof urlToEventMapping) || null;
      if (route && urlToEventMapping[route]) {
        triggerEvent(urlToEventMapping[route]);
      }
    };

    const handleRouteChangeError = () => {};

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeError', handleRouteChangeError);
    window.addEventListener('online', setOnline.bind(null, true));
    window.addEventListener('offline', setOnline.bind(null, false));

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeError', handleRouteChangeError);
      window.removeEventListener('online', setOnline.bind(null, true));
      window.removeEventListener('offline', setOnline.bind(null, false));
    };
  }, []);

  const setAppIdInStorage = () => {
    const storedAppId = getItem(STORAGE_KEYS.APP_ID);
    const appID = process.env.NEXT_PUBLIC_APP_ID;
    if (appID && storedAppId && appID == storedAppId) {
      return;
    }

    if (appID) {
      setItem(STORAGE_KEYS.APP_ID, appID);
    }
  };

  return (
    <main className={`tw-min-w-screen tw-h-screen tw-min-h-screen tw-w-screen`}>
      {online ? (
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <GlobalContextProvider>
              <RouteGuard>
                {systemUserId ? (
                  <SplitFactory config={sdkConfig}>
                    <Component {...pageProps} />
                  </SplitFactory>
                ) : (
                  <Component {...pageProps} />
                )}
              </RouteGuard>
            </GlobalContextProvider>
          </Hydrate>
        </QueryClientProvider>
      ) : (
        <div className="tw-h-screen tw-w-full">
          <Header headerTitle="" />
          <div className="tw-flex tw-h-screen tw-w-full tw-flex-col tw-items-center tw-justify-center  tw-pt-[40px]">
            <div className="tw-flex tw-items-center tw-justify-between tw-space-x-2">
              <div className="-tw-mr-8">
                <Exclamation color="red" height={100} width={100} />
              </div>
              <div>
                <Wifi className="tw-text-slate-400" height={100} width={100} />
              </div>
            </div>
            <div className="tw-flex tw-text-center tw-text-slate-400">
              <label>
                You are offline! Please check your internet connection
              </label>
            </div>
            <div className="tw-mt-4 tw-rounded tw-bg-[#5fa744]">
              <button
                className="tw-p-2 tw-uppercase tw-text-white"
                onClick={() => {}}
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}

export default MyApp;
